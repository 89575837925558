<template>
  <div class="conflict">
    <div class="title">利益冲突检查</div>
    <div class="serchBox">
      <el-input v-model="conflictSerch" placeholder="输入关键字按回车检索" 
      @keyup.enter="serchKey" clearable style='width:330px'>
        <template #prefix>
          <i class="iconfont iconfangdajing"></i>
        </template>
      </el-input>
    </div>
    <div class="conflictTable">
      <el-table
        :data="conflictTableData"
        stripe
        style="width: 100%">
        <el-table-column
          prop="order"
          label="案件/项目编号"
          width="200">
        </el-table-column>
        <el-table-column
          prop="caseName"
          label="名称"
          width="220">
        </el-table-column>
        <el-table-column
          prop="party"
          label="委托方当事人">
          <template #default="scope">
            <span class='partyType'>
              {{ scope.row.party.type }}
            </span>
            <span>
              {{ scope.row.party.name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="otherParty"
          label="对方当事人">
          <template #default="scope">
            <span class='partyType'>
              {{ scope.row.otherParty.type }}
            </span>
            <span>
              {{ scope.row.otherParty.name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="lawyer"
          label="经办律师"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
        >
        </el-table-column>
        <el-table-column
          prop="date"
          label="立案/立项时间"
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="PAGE_SIZES"
        :page-size="PAGE_SIZE"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { useConst } from '@/hooks/usePagination.js';
import { defineComponent,ref } from 'vue';
// 分页
const useCurrentPagination = () => {
  let currentPage = ref(1);
  let totalPage = ref(1);
  const SizeChange = (val) => {
    console.log(`每页 ${val} 条`);
  };
  const CurrentChange = (val) => {
    console.log(`当前页: ${val}`);
  };
  return {
    SizeChange,
    CurrentChange,
    currentPage,
    totalPage
  }
}
export default defineComponent({
  name:'conflict',
  setup(){
    const {
      SizeChange,
      CurrentChange,
      currentPage,
      totalPage
    } = useCurrentPagination();
    const {
      PAGE_SIZE,
      PAGE_SIZES,
      handleSizeChange,
      handleCurrentChange
    } = useConst(SizeChange, CurrentChange);
    return {
      PAGE_SIZE,
      PAGE_SIZES,
      handleSizeChange,
      handleCurrentChange,
      SizeChange,
      CurrentChange,
      currentPage,
      totalPage
    }
  },
  data(){
    return {
      conflictSerch:'',
      conflictTableData:[{
        date: '2016-05-02',
        caseName: '张三入室盗窃杀人',
        order: '苏永民字(2020)第201号',
        party:{
          type:'原告',
          name:'李四'
        },
        otherParty:{
          type:'被告',
          name:'王五'
        },
        lawyer:'张律师',
        status:'已结案'
      },{
        date: '2016-05-02',
        caseName: '张三入室盗窃杀人案',
        order: '苏永民字(2020)第201号',
        party:{
          type:'原告',
          name:'李四'
        },
        otherParty:{
          type:'被告',
          name:'王五'
        },
        lawyer:'张律师',
        status:'已结案'
      }],
    }
  },
  methods:{
    serchKey(){
      console.log('serch')
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/comman.scss';
.conflict{
  width:1200px;
  margin:0 auto;
  background: #fff;
  border-radius:20px;
  padding:30px;
  .title{
    color:$main-color;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-bottom:30px;
  }
  .serchBox{
    border-top:1px solid #eee;
    border-bottom:1px solid #eee;
    padding:30px 0;
    ::v-deep{
      .el-input--prefix .el-input__inner{
        border-radius:20px;
      }
    }
  }
  .conflictTable{
    .partyType{
      margin-right: 10px;
      color:#999;
    }
  }
  .pagination{
    display: flex;
    justify-content: flex-end;
    margin-top:30px;
  }
}
</style>